///
/// Solid State by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Type */

	body, input, select, textarea {
		color: _palette(fg);
		font-family: _font(family);
		font-size: 16.5pt;
		font-weight: _font(weight);
		line-height: 1.65;

		@include breakpoint('<=xlarge') {
			font-size: 13pt;
		}

		@include breakpoint('<=large') {
			font-size: 12pt;
		}

		@include breakpoint('<=medium') {
			font-size: 12pt;
		}

		@include breakpoint('<=small') {
			font-size: 12pt;
		}

		@include breakpoint('<=xsmall') {
			font-size: 12pt;
		}
	}

	a {
		@include vendor('transition', 'color #{_duration(transition)} ease-in-out, border-bottom-color #{_duration(transition)} ease-in-out');
		border-bottom: dotted 1px _palette(fg-light);
		color: _palette(fg-bold);
		text-decoration: none;

		&:hover {
			border-bottom-color: transparent;
			color: _palette(fg-bold) !important;
		}

		&.special:not(.button) {
			@include icon(false, solid);
			border-bottom: 0;
			display: block;
			font-family: _font(family-heading);
			font-size: 0.8em;
			font-weight: _font(weight-heading-bold);
			letter-spacing: _font(kern-heading);
			margin: 0 0 _size(element-margin) 0;
			text-transform: uppercase;

			&:before {
				@include vendor('transition', 'background-color #{_duration(transition)} ease-in-out');
				border-radius: 100%;
				border: solid 2px _palette(border);
				content: '\f105';
				display: inline-block;
				font-size: 1.25em;
				height: 2em;
				line-height: 1.75em;
				margin-right: 0.85em;
				text-align: center;
				text-indent: 0.15em;
				vertical-align: middle;
				width: 2em;
			}

			&:hover {
				&:before {
					background-color: _palette(border-bg);
				}
			}

			&:active {
				&:before {
					background-color: _palette(border2-bg);
				}
			}
		}
	}

	strong, b {
		color: _palette(fg-bold);
		font-weight: _font(weight-bold);
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 _size(element-margin) 0;
	}

	h1, h2, h3, h4, h5, h6 {
		color: _palette(fg-bold);
		font-family: _font(family-heading);
		font-weight: _font(weight-heading-bold);
		letter-spacing: _font(kern-heading);
		margin: 0 0 (_size(element-margin) * 0.5) 0;
		text-transform: uppercase;

		a {
			color: inherit;
			text-decoration: none;
			border-bottom: 0;
		}

		span {
			font-weight: _font(weight-heading);
		}

		&.major {
			padding-bottom: 1em;
			border-bottom: solid 2px _palette(border);
		}
	}

	h2 {
		font-size: 1.2em;
	}

	h3 {
		font-size: 0.9em;
	}

	h4 {
		font-size: 0.7em;
	}

	h5 {
		font-size: 0.7em;
	}

	h6 {
		font-size: 0.7em;
	}

	@include breakpoint('<=small') {
		h2 {
			font-size: 1em;
		}

		h3 {
			font-size: 0.8em
		}
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid 4px _palette(border);
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: (_size(element-margin) / 4) 0 (_size(element-margin) / 4) _size(element-margin);
	}

	code {
		background: _palette(border-bg);
		border-radius: _size(border-radius);
		border: solid 2px _palette(border);
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0 _size(element-margin) 0;

		code {
			display: block;
			line-height: 1.75em;
			padding: 1em 1.5em;
			overflow-x: auto;
		}
	}

	hr {
		border: 0;
		border-bottom: solid 2px _palette(border);
		margin: (_size(element-margin) * 1.25) 0;

		&.major {
			margin: (_size(element-margin) * 2) 0;
		}
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}